import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import qs from 'query-string';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import logologin from '../../assets/logologin.png';
import signup from '../../assets/signup.png'; // Imagem de fundo
import toastError from '../../errors/toastError';
import usePlans from '../../hooks/usePlans';
import { openApi } from '../../services/api';
import { i18n } from '../../translate/i18n';
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#F5F5F9",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  leftContainer: {
        width: "70%", // Mantendo a largura ajustada
        background: `url(${signup})`,
        backgroundSize: "cover", // Mantém a imagem cobrindo todo o container
        backgroundPosition: "calc(100% + 150px) center", // Movendo a imagem 50px para a direita
        backgroundRepeat: "no-repeat",
        //backgroundSize: "50%", // Ajuste para "50%" para reduzir o tamanho da imagem
        backgroundPosition: "center",
        [theme.breakpoints.down('sm')]: {
            display: "none", // Esconde o leftContainer em telas pequenas
        },
    },
    rightContainer: {
        width: "30%", // Mantendo a largura ajustada
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        alignItems: "center",
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: "100%", // Ocupa 100% da largura em dispositivos móveis
        },
    },
  paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        padding: theme.spacing(1),
        borderRadius: "10px",
        width: "120%",
        maxWidth: "400px",
    },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    borderRadius: '7px',
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    color: "#ffffff",
  },
  underline: {
    "&::before": {
      borderBottom: "1px solid #ffffff",
    },
  },
  powered: {
    color: "#666666",
    textAlign: "center",
    marginTop: "20px",
  },
  whatsappButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#7FB480', // Cor de fundo cinza
    color: '#264331', // Cor do texto preto
    height: '50px',
    borderRadius: '7px',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    '&:hover': {
      backgroundColor: '#7FB480', // Cor cinza mais escura ao passar o mouse
    },
  },
  submit: {
    marginTop: theme.spacing(2),
    backgroundColor: '#264331',
    color: '#fff', // Cor do texto branco
    height: '50px',
    borderRadius: '7px',
    marginBottom: theme.spacing(3), 
    '&:hover': {
      backgroundColor: '#264331', 
    },
  },
  link: {
    color: '#00bfff',
    textDecoration: 'none',
    marginTop: theme.spacing(3), // Adiciona margem superior para criar espaçamento
    display: 'block',
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  logo: {
        margin: "0 auto",
        width: "80%",
        marginBottom: theme.spacing(2),
    },
}));

const handleNewUserMessage = (newMessage) => {
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(newMessage)}`;
  window.open(url, "_blank");
};

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Muito curto!')
    .max(50, 'Muito extenso!')
    .required('Obrigatório'),
  companyName: Yup.string()
    .min(2, 'Muito curto!')
    .max(50, 'Muito extenso!')
    .required('Obrigatório'),
  password: Yup.string()
    .min(5, 'Muito curto!')
    .max(50, 'Muito extenso!')
    .required('Obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .required('Obrigatório'),
  phone: Yup.string()
    .required('O número de telefone é obrigatório')
    .matches(
      /^(?:\+?\d{1,3}[-.\s]?)?\(?(?:\d{2,3})\)?[-.\s]?\d{4,5}[-.\s]?\d{4}$/,
      'Número de telefone inválido. Por favor, insira um número de telefone válido do Brasil ou internacional.'
    ),
  planId: Yup.string().required('Obrigatório'),
});

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const [allowregister, setallowregister] = useState('enabled');
    const [trial, settrial] = useState('3');
  let companyId = null

  useEffect(() => {
        fetchallowregister();
        fetchtrial();
    }, []);


    const fetchtrial = async () => {
  
 
    try {
        const responsevvv = await api.get("/settings/trial");
        const allowtrialX = responsevvv.data.value;
        //console.log(allowregisterX);
        settrial(allowtrialX);
        } catch (error) {
            console.error('Error retrieving trial', error);
        }
    };


    const fetchallowregister = async () => {
  
 
    try {
        const responsevv = await api.get("/settings/allowregister");
        const allowregisterX = responsevv.data.value;
        //console.log(allowregisterX);
        setallowregister(allowregisterX);
        } catch (error) {
            console.error('Error retrieving allowregister', error);
        }
    };

    if(allowregister === "disabled"){
      history.push("/login");    
    }

  const params = qs.parse(window.location.search)
  if (params.companyId !== undefined) {
    companyId = params.companyId
  }

  const initialState = { name: '', email: '', password: '', phone: '', companyName: '', planId: '' };
  const [user, setUser] = useState(initialState);
  const [isCodeEnabled, setIsCodeEnabled] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);

  const dueDate = moment().add(trial, "day").format();

  const handleSignUp = async (values) => {
    if (!validationCode) {
      toast.error("Por favor, solicite o seu código de validação.");
      return;
    }

    Object.assign(values, { recurrence: 'MENSAL' });
    Object.assign(values, { dueDate: dueDate });
    Object.assign(values, { status: 't' });
    Object.assign(values, { campaignsEnabled: true });

    try {
      await openApi.post('/companies/cadastro', values);
      toast.success(i18n.t('signup.toasts.success'));
      history.push('/login');
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const [plans, setPlans] = useState([]);
  const { list: listPlans } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const list = await listPlans();
      setPlans(list);
    }
    fetchData();
  }, []);

  const handleWhatsAppClick = () => {
    const welcomeMessage = "🚀 Bem-vindo(a) ao nosso serviço! 🌟\nAguarde enquanto verificamos seu código de validação.\n\n";
    const userDetails = `🏢 Organização: ${user.companyName}\n👤 Nome: ${user.name}\n✉️ Email: ${user.email}\n📱 WhatsApp: ${user.phone}\n💼 Plano: ${user.planId}\n\n`;
    const message = welcomeMessage + userDetails;
    handleNewUserMessage(message);
    setIsCodeEnabled(true);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer} />
      <div className={classes.rightContainer}>
        <Container className={classes.paper}>
          <CssBaseline />
          <img className={classes.logo} src={logologin} alt="Logo" style={{ marginBottom: '16px', marginTop: '40px' }} />
          <Typography component="h1" variant="h5">
            {i18n.t('signup.title')}
          </Typography>
          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={UserSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSignUp(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      id="companyName"
                      label={i18n.t('signup.form.company')}
                      error={touched.companyName && Boolean(errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                      name="companyName"
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      id="name"
                      label={i18n.t('signup.form.name')}
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      id="email"
                      label={i18n.t('signup.form.email')}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      name="password"
                      label={i18n.t('signup.form.password')}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      id="password"
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      type="number"
                      fullWidth
                      id="phone"
                      label={i18n.t('signup.form.phone')}
                      name="phone"
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="planId">Selecione seu plano de assinatura</InputLabel>
                      <Select
                        variant="outlined"
                        fullWidth
                        label="Selecione seu plano de assinatura"
                        labelId="planId"
                        id="planId"
                        name="planId"
                        required
                        onChange={handleInputChange}
                      >
                        {plans.map((plan, key) => (
                          <MenuItem key={key} value={plan.id}>
                            {plan.name} - Atendentes: {plan.users} - WhatsApp: {plan.connections} - Filas: {plan.queues} - R$ {plan.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {isCodeEnabled && (
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        name="validationCode"
                        label="Código de validação"
                        id="validationCode"
                        autoComplete="validation-code"
                        required
                        value={validationCode}
                        onChange={(e) => setValidationCode(e.target.value)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.whatsappButton}
                  startIcon={<VpnKeyIcon />}
                  onClick={handleWhatsAppClick}
                >
                  Solicite Seu Código
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                >
                  {i18n.t('signup.buttons.submit')}
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      component={RouterLink}
                      to="/login"
                    >
                      {i18n.t('signup.buttons.login')}
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </div>
  );
};

export default SignUp;
