import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button, MenuItem, Select, FormControl, InputLabel, Typography, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import usePlans from '../../hooks/usePlans';
import toastError from '../../errors/toastError';
import { openApi } from '../../services/api';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    maxWidth: '800px',
    margin: '0 auto',
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: '#ffffff',
    borderRadius: 15,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: '#333333',
  },
  submit: {
    marginTop: theme.spacing(2),
    backgroundColor: '#556EE6',
    color: '#fff',
    height: '45px',
    width: '100%',
    borderRadius: '22px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#4455cc',
    },
  },
  inputField: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    '& .MuiInputBase-root': {
      color: '#000',
      backgroundColor: '#FAFAFA',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: 'transparent',
    },
    '&:hover .MuiFilledInput-underline:before': {
      borderBottomColor: 'transparent',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .Mui-focused .MuiFilledInput-underline:after': {
      borderBottomColor: '#4B44C1',
    },
    '& .MuiFilledInput-input': {
      padding: '10px 12px',
      borderRadius: 8,
      border: '1px solid #ccc',
    },
    '& .Mui-focused .MuiFilledInput-input': {
      border: '1px solid #4B44C1',
    },
    width: '100%',
  },
  inputLabel: {
    color: '#757575',
    marginBottom: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  passwordInputField: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    '& .MuiInputBase-root': {
      color: '#000',
      backgroundColor: '#FAFAFA',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: 'transparent',
    },
    '&:hover .MuiFilledInput-underline:before': {
      borderBottomColor: 'transparent',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .Mui-focused .MuiFilledInput-underline:after': {
      borderBottomColor: '#4B44C1',
    },
    '& .MuiFilledInput-input': {
      padding: '10px 12px',
      borderRadius: 8,
      border: '1px solid #ccc',
    },
    '& .Mui-focused .MuiFilledInput-input': {
      border: '1px solid #4B44C1',
    },
    width: '100%',
  },
  iconButton: {
    padding: 10,
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Muito curto!').max(50, 'Muito extenso!'),
  companyName: Yup.string().min(2, 'Muito curto!').max(50, 'Muito extenso!'),
  password: Yup.string().min(5, 'Muito curto!').max(50, 'Muito extenso!'),
  email: Yup.string().email('Email inválido'),
  phone: Yup.string().matches(
    /^(?:\+?\d{1,3}[-.\s]?)?\(?(?:\d{2,3})\)?[-.\s]?\d{4,5}[-.\s]?\d{4}$/,
    'Número de telefone inválido. Por favor, insira um número de telefone válido do Brasil ou internacional.'
  ),
  planId: Yup.string(),
});

const CompanyForm = ({ initialValues, onSubmit }) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [plans, setPlans] = useState([]);
  const { list: listPlans } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const list = await listPlans();
      setPlans(list);
    }
    fetchData();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = (values, { resetForm }) => {
    onSubmit(values);
    resetForm();
    setStep(1);
  };

  return (
    <Formik
      initialValues={{ ...initialValues, planId: initialValues.planId || (plans[0] && plans[0].id) }}
      validationSchema={UserSchema}
      onSubmit={handleSubmit}
    >
      {({ touched, errors, handleChange, handleBlur, values }) => (
        <Form className={classes.form}>
          {step === 1 && (
            <>
              <Typography className={classes.sectionTitle}>Configurações Gerais</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>{i18n.t('signup.form.company')}</InputLabel>
                  <Field
                    as={TextField}
                    variant="filled"
                    fullWidth
                    id="companyName"
                    error={touched.companyName && Boolean(errors.companyName)}
                    helperText={touched.companyName && errors.companyName}
                    name="companyName"
                    autoComplete="companyName"
                    autoFocus
                    className={classes.inputField}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>{i18n.t('signup.form.name')}</InputLabel>
                  <Field
                    as={TextField}
                    autoComplete="name"
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="filled"
                    fullWidth
                    id="name"
                    className={classes.inputField}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>{i18n.t('signup.form.email')}</InputLabel>
                  <Field
                    as={TextField}
                    variant="filled"
                    fullWidth
                    id="email"
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    autoComplete="email"
                    className={classes.inputField}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>{i18n.t('signup.form.password')}</InputLabel>
                  <Field
                    as={TextField}
                    variant="filled"
                    fullWidth
                    name="password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                            className={classes.iconButton}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    autoComplete="current-password"
                    className={classes.passwordInputField}
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonGroup}>
                <Button
                  type="button"
                  variant="contained"
                  className={classes.submit}
                  onClick={handleNext}
                >
                  Próximo
                </Button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <Typography className={classes.sectionTitle}>Configurações de Contato</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>{i18n.t('signup.form.phone')}</InputLabel>
                  <Field
                    as={TextField}
                    variant="filled"
                    fullWidth
                    id="phone"
                    name="phone"
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    autoComplete="phone"
                    inputProps={{ maxLength: 15 }}
                    className={classes.inputField}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonGroup}>
                <Button
                  type="button"
                  variant="contained"
                  className={classes.submit}
                  onClick={handleBack}
                >
                  Voltar
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  className={classes.submit}
                  onClick={handleNext}
                >
                  Próximo
                </Button>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <Typography className={classes.sectionTitle}>Configurações de Plano</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>Selecione seu plano de assinatura</InputLabel>
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.inputField}
                  >
                    <Select
                      variant="filled"
                      fullWidth
                      label="Selecione seu plano de assinatura"
                      labelId="planId"
                      id="planId"
                      name="planId"
                      value={values.planId}
                      onChange={handleChange}
                      className={classes.inputField}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                    >
                      {plans.map((plan, key) => (
                        <MenuItem key={key} value={plan.id}>
                          {plan.name} - Atendentes: {plan.users} - WhatsApp: {plan.connections} - Filas: {plan.queues} - R$ {plan.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.buttonGroup}>
                <Button
                  type="button"
                  variant="contained"
                  className={classes.submit}
                  onClick={handleBack}
                >
                  Voltar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                >
                  Adicionar Empresa
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CompanyForm;
