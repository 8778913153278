import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import {
  Field,
  Form,
  Formik,
} from 'formik';
import moment from 'moment';
import qs from 'query-string';
import {
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import reset from '../../assets/reset.png';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import logo from '../../assets/logologin.png';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#F5F5F9",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  leftContainer: {
        width: "70%", // Mantendo a largura ajustada
        background: `url(${reset})`,
        backgroundSize: "cover", // Mantém a imagem cobrindo todo o container
        backgroundPosition: "calc(100% + 150px) center", // Movendo a imagem 50px para a direita
        backgroundRepeat: "no-repeat",
        //backgroundSize: "50%", // Ajuste para "50%" para reduzir o tamanho da imagem
        backgroundPosition: "center",
        [theme.breakpoints.down('sm')]: {
            display: "none", // Esconde o leftContainer em telas pequenas
        },
    },
  rightContainer: {
    width: "30%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  paper: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "55px 30px",
    borderRadius: "12.5px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
    borderRadius: "7px",
    height: "50px", // Ajuste a altura conforme necessário
    outline: "none", // Remover o efeito de borda
  },
  powered: {
    color: "white",
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  linkIcon: {
    marginRight: theme.spacing(1),
  },
}));

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const ForgetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  let companyId = null;
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showResetPasswordButton, setShowResetPasswordButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(""); // Estado para mensagens de erro

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const toggleAdditionalFields = () => {
    setShowAdditionalFields(!showAdditionalFields);
    if (showAdditionalFields) {
      setShowResetPasswordButton(false);
    } else {
      setShowResetPasswordButton(true);
    }
  };

  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = { email: "" };
  const [user] = useState(initialState);
  const dueDate = moment().add(3, "day").format();

  const handleSendEmail = async (values) => {
    const email = values.email;
    try {
      const response = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/forgetpassword/${email}`
      );
      console.log("API Response:", response.data);

      if (response.data.status === 404) {
        toast.error("Email não encontrado");
      } else {
        toast.success(i18n.t("Email enviado com sucesso!"));
      }
    } catch (err) {
      console.log("API Error:", err);
      toastError(err);
    }
  };

  const handleResetPassword = async (values) => {
    const email = values.email;
    const token = values.token;
    const newPassword = values.newPassword;
    const confirmPassword = values.confirmPassword;

    if (newPassword === confirmPassword) {
      try {
        await api.post(
          `${process.env.REACT_APP_BACKEND_URL}/resetpasswords/${email}/${token}/${newPassword}`
        );
        setError(""); // Limpe o erro se não houver erro
        toast.success(i18n.t("Senha redefinida com sucesso."));
        history.push("/login");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const isResetPasswordButtonClicked = showResetPasswordButton;
  const UserSchema = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Obrigatório"),
    newPassword: isResetPasswordButtonClicked
      ? Yup.string()
          .required("Campo obrigatório")
          .matches(
            passwordRegex,
            "Sua senha precisa ter no mínimo 8 caracteres, sendo uma letra maiúscula, uma minúscula e um número."
          )
      : Yup.string(), // Sem validação se não for redefinição de senha
    confirmPassword: Yup.string().when("newPassword", {
      is: (newPassword) => isResetPasswordButtonClicked && newPassword,
      then: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "As senhas não correspondem")
        .required("Campo obrigatório"),
      otherwise: Yup.string(), // Sem validação se não for redefinição de senha
    }),
  });

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer} />
      <div className={classes.rightContainer}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <div>
              <img
                style={{ margin: "0 auto", height: "80px", width: "100%" }}
                src={logo}
                alt="Whats"
              />
            </div>
            <Typography component="h1" variant="h5" style={{ marginBottom: '16px' }}>
              Esqueceu a senha 🔒
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '24px', textAlign: 'center' }}>
              Insira seu e-mail e enviaremos instruções para redefinir sua senha.
            </Typography>
            <Formik
              initialValues={{
                email: "",
                token: "",
                newPassword: "",
                confirmPassword: "",
              }}
              enableReinitialize={true}
              validationSchema={UserSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  if (showResetPasswordButton) {
                    handleResetPassword(values);
                  } else {
                    handleSendEmail(values);
                  }
                  actions.setSubmitting(false);
                  toggleAdditionalFields();
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        autoComplete="email"
                        required
                      />
                    </Grid>
                    {showAdditionalFields && (
                      <>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            id="token"
                            label="Código de Verificação"
                            name="token"
                            error={touched.token && Boolean(errors.token)}
                            helperText={touched.token && errors.token}
                            autoComplete="off"
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            id="newPassword"
                            label="Nova senha"
                            name="newPassword"
                            error={touched.newPassword && Boolean(errors.newPassword)}
                            helperText={touched.newPassword && errors.newPassword}
                            autoComplete="off"
                            required
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={togglePasswordVisibility}
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirmPassword"
                            label="Confirme a senha"
                            name="confirmPassword"
                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                            autoComplete="off"
                            required
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={toggleConfirmPasswordVisibility}
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {showResetPasswordButton ? (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Redefinir senha
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Enviar link de redefinição
                    </Button>
                  )}
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link
                        href="#"
                        variant="body2"
                        component={RouterLink}
                        to="/login"
                        className={classes.linkContainer}
                      >
                        <KeyboardArrowLeftIcon className={classes.linkIcon} />
                        {i18n.t("Voltar para Login")}
                      </Link>
                    </Grid>
                  </Grid>
                  {error && (
                    <Typography variant="body2" color="error">
                      {error}
                    </Typography>
                  )}
                </Form>
              )}
            </Formik>
          </div>
          <Box mt={5} />
        </Container>
      </div>
    </div>
  );
};

export default ForgetPassword;
