import React, { useContext, useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
    Box,
    Grid,
    IconButton,
    InputAdornment,
    Container,
    CssBaseline,
    Link,
    Button,
    TextField,
    Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'; // Ícone do botão "Criar uma conta"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import logologin from '../../assets/logologin.png';
import nextjs from '../../assets/nextjs.png';
import { AuthContext } from '../../context/Auth/AuthContext';
import { i18n } from '../../translate/i18n';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../services/api';
import { useTheme } from '@material-ui/core/styles';

const Copyright = () => {
    const classes = useStyles();

    return (
        <Typography variant="body2" align="center" className={classes.poweredByContainer}>
            <span style={{ color: 'white' }}>© 2007 - 2024 Inovando. Todos os direitos reservados.</span>
        </Typography>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#F5F5F9",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    leftContainer: {
        width: "70%",
        background: `url(${nextjs})`,
        backgroundSize: "cover",
        backgroundSize: "50%", // Ajuste para "50%" para reduzir o tamanho da imagem
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },
    rightContainer: {
        width: "30%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        alignItems: "center",
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        padding: theme.spacing(2),
        borderRadius: "10px",
        width: "100%",
        maxWidth: "400px",
    },
    logo: {
        margin: "0 auto",
        width: "80%",
        marginBottom: theme.spacing(2),
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
        borderRadius: "7px",
        backgroundColor: "#193F2F",
        color: "white",
        '&:hover': {
            backgroundColor: "#102B1F",
        },
        textTransform: "none",
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    signUpContainer: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    createAccountButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Centraliza horizontalmente
        color: '#193F2F',
        textTransform: 'none',
        marginTop: theme.spacing(1),
        '&:hover': {
            textDecoration: 'underline', // Adiciona sublinhado ao passar o mouse
        },
    },
}));

const Login = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const [user, setUser] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [allowregister, setAllowRegister] = useState('disabled');
    const [viewregister, setViewRegister] = useState('disabled');

    const { handleLogin } = useContext(AuthContext);

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = e => {
        e.preventDefault();
        handleLogin(user);
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const responseAllow = await api.get("/settings/allowregister");
                setAllowRegister(responseAllow.data.value);

                const responseView = await api.get("/settings/viewregister");
                setViewRegister(responseView.data.value);

                if (responseAllow.data.value === 'disabled') {
                    history.push("/login");
                }
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        };

        fetchSettings();
    }, [history]);

    return (
        <div className={classes.root}>
            <div className={classes.leftContainer} />
            <div className={classes.rightContainer}>
                <Container className={classes.paper}>
                    <CssBaseline />
                    <img className={classes.logo} src={logologin} alt="Logo" />
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            id="email"
                            label={i18n.t("login.form.email")}
                            name="email"
                            value={user.email}
                            onChange={handleChangeInput}
                            autoComplete="email"
                            autoFocus
                            InputLabelProps={{
                                style: { color: '#000', opacity: 0.6 },
                                required: false,
                            }}
                        />

                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            name="password"
                            label={i18n.t("login.form.password")}
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={user.password}
                            onChange={handleChangeInput}
                            autoComplete="current-password"
                            InputLabelProps={{
                                style: { color: '#000', opacity: 0.6 },
                                required: false,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(prev => !prev)}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        
                        <Grid container justify="flex-end">
                            <Grid item xs={12}>
                                <Link component={RouterLink} to="/forgetpsw" variant="body2">
                                    Esqueceu sua senha?
                                </Link>
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {i18n.t("login.buttons.submit")}
                        </Button>
                    </form>

                    {allowregister === "enabled" && viewregister === "enabled" && (
                        <Container className={classes.signUpContainer} maxWidth="xs">
                            <Typography variant="body1" color="textSecondary">
                                Ainda não tem uma conta?
                            </Typography>
                            <Link component={RouterLink} to="/signup" className={classes.createAccountButton}>
                                <KeyboardArrowRightIcon style={{ marginRight: 4 }} />
                                Criar uma conta
                            </Link>
                        </Container>
                    )}
                    <Box mt={2}>
                        <Copyright />
                    </Box>
                </Container>
            </div>
        </div>
    );
};

export default Login;
